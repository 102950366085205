import styled from 'styled-components';
import { colors } from '../../assets/styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 3fr;
  grid-column-gap: 20vh 70vh 10vh;
  grid-row-gap: 3rem;
  grid-template-areas: 'c1 c2 c3 c4';

  /* background: #000; */
  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'c1 c2';
  }
`;

export const CardMember = styled.div`
  width: 300px;
  height: auto;
  grid-area: c1 c2 c3 c4;

  h1,
  p {
    color: ${colors.primary};
  }
  h1 {
    font: 700 1.8rem Poppins;
  }

  p {
    font: 400 1.2rem Poppins;
  }

  @media (max-width: 700px) {
    width: 150px;
    grid-area: c1 c2;

    h1 {
      font: 700 1rem Poppins;
    }

    p {
      font: 400 0.8rem Poppins;
    }
  }
`;
