import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useField } from '@unform/core';

import { FiAlertCircle, FiEye, FiEyeOff } from 'react-icons/fi';
import { Container, Error } from './styles';
import colors from '../../assets/styles/colors';

const Input = ({ name, icon: Icon, isFieldPass = false, ...rest }) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { fieldName, error, defaultValue, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      {Icon && <Icon size={20} />}
      <input
        defaultValue={defaultValue}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        ref={inputRef}
        {...rest}
        type={showPassword ? 'text' : rest.type}
      />
      {isFieldPass &&
        (showPassword ? (
          <FiEye
            size={20}
            style={{ cursor: 'pointer' }}
            onClick={() => setShowPassword(false)}
          />
        ) : (
          <FiEyeOff
            size={20}
            style={{ cursor: 'pointer' }}
            onClick={() => setShowPassword(true)}
          />
        ))}
      {error && (
        <Error title={error}>
          <FiAlertCircle color={colors.failure} size={20} />
        </Error>
      )}
    </Container>
  );
};

export default Input;
