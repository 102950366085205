import React from 'react';
import { Container, Content } from './styles';
import logo from '../../assets/images/logos/logo.svg';

function Main() {
  return (
    <>
      <Container id="home">
        <Content>
          <img className="logo_main" src={logo} alt="logo" />
          <h1>OTIMIZAÇÃO DE PROCESSOS PRODUTIVOS</h1>
        </Content>
      </Container>
    </>
  );
}

export default Main;
