import styled from 'styled-components';
import { colors } from '../../assets/styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 500px;
  background: ${colors.primary};
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerCarousel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: auto;
  margin-top: 3rem;

  @media (max-width: 700px) {
    width: 80%;
  }
`;

export const CardProject = styled.div`
  width: 300px;
  height: 300px;
  padding: 1.2rem;

  border: 10px solid ${colors.tertiary};
  border-radius: 10px;
  background: ${colors.secundary};

  h1,
  p {
    color: ${colors.primary};
  }
  h1 {
    font: 700 1.8rem Poppins;
  }

  p {
    font: 400 1.2rem Poppins;
  }

  @media (max-width: 700px) {
    width: 250px;
    height: 250px;
    h1 {
      font: 700 1.4rem Poppins;
    }

    p {
      font: 400 0.8rem Poppins;
    }
  }
`;
