import styled from 'styled-components';
import { colors } from '../../assets/styles';

export const Container = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background: ${colors.primary};
  /* position: fixed; */
  /* @media (max-width: 700px) {
    width: 90%;
  } */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 700px;

  form {
    margin: 0.8rem 0;
    padding: 2rem;
    width: 420px;
    border-radius: 2px;
    text-align: center;
  }

  @media (max-width: 700px) {
    form {
      padding: 1rem;
      width: 250px;
    }
    font: 400 0.8rem Poppins;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 4rem;
  background: ${colors.tertiary};
  color: ${colors.primary};
  border-radius: 5px;
  font: 700 1.2rem Poppins;
  cursor: pointer;
  margin-top: 1rem;

  @media (max-width: 700px) {
    font: 700 0.9rem Poppins;
  }
`;
