import styled, { css } from 'styled-components';
import colors from '../../assets/styles/colors';

export const Container = styled.div.attrs((props) => ({
  disabled: props.showOptionsMenu,
}))`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.primary};
  width: 100vw;
  height: 10vh;
  position: fixed;
  top: 0;
  z-index: 2;
  opacity: ${(props) => props.opacity || 1};
  border-bottom: 2px solid ${colors.primary};

  ${(props) =>
    props.showOptionsMenu
      ? css`
          :hover {
            max-height: 100vh;
            transition: all ease 0.5s;
          }
        `
      : css`
          :hover {
            max-height: 15vh;
            transition: all ease 0.5s;
          }
        `}
`;

export const Logomarca = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7vw;
  height: 7vh;
  padding-left: 3rem;

  img {
    width: 100%;
    cursor: pointer;
  }

  @media (max-width: 700px) {
    padding-left: 0.7rem;
  }
`;

export const Menu = styled.div`
  width: 80vw;
  height: 8vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2rem;
  font: 700 1rem Poppins;

  display: flex;
  align-items: center;

  ul {
    display: inline-flex;
    list-style: none;
  }

  ul li {
    justify-content: flex-start;
    align-items: center;
    margin: 0.3rem;
    padding: 0.3rem;
    cursor: pointer;
  }

  ul li:hover {
    background: ${colors.primary};
  }

  ul li a {
    color: ${colors.secundary};
    font-size: 1rem;
    font-weight: bold;
  }

  ul li a:hover {
    color: ${colors.quaternary};
  }

  @media (max-width: 700px) {
    width: 100vw;

    justify-content: center;

    ul li a {
      font-size: 0.7rem;
    }
  }
`;

export const Pipe = styled.span`
  color: ${colors.primary};
  font-size: 1.2rem;
  font-weight: bold;
`;
