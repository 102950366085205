import styled, { css } from 'styled-components';
import { colors } from '../../assets/styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 125px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 100px;
  position: absolute;
  left: 0%;

  ${(props) =>
    props.inverted &&
    css`
      transform: rotate(180deg);
      left: 5%;
    `}
`;

export const Retangle = styled.div`
  width: 30%;
  height: 4rem;
  border: 8px solid ${colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    font: 700 1.5rem Poppins;
    color: ${colors.primary};
  }

  ${(props) =>
    props.inverted &&
    css`
      transform: rotate(180deg);
    `}

  @media (max-width: 700px) {
    h1 {
      font-size: 1rem;
    }
  }
`;

export const Line = styled.div`
  width: 70%;
  height: 0.5rem;
  background: ${colors.primary};
`;
