import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { Container, Menu, Logomarca } from './styles';

import logo from '../../assets/images/logos/logo3.svg';

function Header() {
  const [widthWindow, setWidthWindow] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidthWindow(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // function handleClickDropMenu() {
  //   setShowOptionsMenu(!showOptionsMenu);
  // }

  return (
    <>
      <Container>
        {widthWindow > 700 && (
          <Logomarca>
            <Link activeClass="active" to="home" spy smooth>
              <img src={logo} alt="logo" />
            </Link>
          </Logomarca>
        )}
        <Menu>
          <ul>
            <li>
              <Link to="about" spy smooth>
                SOBRE
              </Link>
            </li>
            <li>
              <Link to="solutions" spy smooth>
                SOLUÇÕES
              </Link>
            </li>
            <li>
              <Link to="clients" spy smooth>
                CLIENTES
              </Link>
            </li>
            <li>
              <Link to="equip" spy smooth>
                EQUIPE
              </Link>
            </li>
            <li>
              <Link to="contact" spy smooth>
                CONTATO
              </Link>
            </li>
          </ul>
        </Menu>
      </Container>
    </>
  );
}

export default Header;
