import React from 'react';

import { Container, Content, CardMember } from './styles';

function Equip() {
  const members = [
    {
      id: 1,
      name: 'Caio Alves',
      occupation: 'Engenheiro metalúrgico',
      lattes: '#lattes',
    },
    {
      id: 2,
      name: 'Igor Jurandir',
      occupation: 'Engenheiro metalúrgico',
      lattes: '#lattes',
    },
    {
      id: 3,
      name: 'Pedro Paulo',
      occupation: 'Engenheiro metalúrgico',
      lattes: '#lattes',
    },
    {
      id: 4,
      name: 'Patrick Moreira',
      occupation: 'Desenvolvedor de software',
      lattes: '#lattes',
    },
    {
      id: 5,
      name: 'Paulo Assis',
      occupation: 'Especialista em processos metalúrgicos',
      lattes: '#lattes',
    },
    {
      id: 6,
      name: 'Marcone Souza',
      occupation: 'Especialista em otimização de processos',
      lattes: '#lattes',
    },
    {
      id: 7,
      name: 'Jorge Brescia',
      occupation: 'Especialista em ciências empresariais',
      lattes: '#lattes',
    },
  ];

  return (
    <Container id="equip">
      <Content>
        {members.map((member) => (
          <CardMember key={member.id}>
            <img
              src={
                require(`../../assets/images/profiles/${member.name}.svg`) // eslint-disable-line import/no-dynamic-require
                  .default
              }
              width={100}
              alt="logo"
            />
            <h1>{member.name}</h1>
            <p>{member.occupation}</p>
            <a href={member.lattes}>
              <b>CV Lattes</b>
            </a>
          </CardMember>
        ))}
      </Content>
    </Container>
  );
}
export default Equip;
