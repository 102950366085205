import React from 'react';

import { Container, Content, Retangle, Line } from './styles';

const Stick = ({ inverted, title }) => {
  return (
    <Container>
      <Content inverted={inverted}>
        <Line />
        <Retangle inverted={inverted}>
          <h1>{title}</h1>
        </Retangle>
      </Content>
    </Container>
  );
};

export default Stick;
