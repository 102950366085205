import React from 'react';

import { Container, Content, CardClient } from './styles';

function Client() {
  const clients = [
    {
      id: 1,
      name: 'VDL Siderúrgica Itabirito',
      logo: 'vdl',
      site: 'http://www.siderurgicaitabirito.com.br/',
    },
  ];

  return (
    <Container id="clients">
      <Content>
        {clients.map((client) => (
          <CardClient key={client.id}>
            <img
              src={
                require(`../../assets/images/clients/${client.logo}.svg`) // eslint-disable-line import/no-dynamic-require
                  .default
              }
              width={180}
              alt="logo"
            />
            <br />
            {/* <h1>{client.name}</h1> */}
            <a href={client.site}>{client.name}</a>
          </CardClient>
        ))}
      </Content>
    </Container>
  );
}
export default Client;
