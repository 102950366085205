import React from 'react';

import {
  Container,
  ContentLeft,
  ContentRight,
  CardLeft,
  CardtRight,
} from './styles';

function About() {
  return (
    <Container id="about">
      <ContentLeft>
        <CardLeft>
          <h1>Missão</h1>
          <p>
            Somos uma empresa comprometida em reformular o futuro das indústrias
            nacionais por meio da otimização de seus processos produtivos.
          </p>
        </CardLeft>
        <CardLeft>
          <h1>Valores</h1>
          <p>
            Valorizamos o respeito entre os nossos colaboradores e somos
            autorresponsáveis pelas nossas decisões e compromissos com os nossos
            clientes.
          </p>
        </CardLeft>
      </ContentLeft>
      <ContentRight>
        <h3>Como entregamos soluções?</h3>
        <CardtRight>
          <h2>1. Contato inicial</h2>
          <p>
            Nos primeiros contatos com o cliente buscamos entender o seu
            contexto para avaliar como podemos ajudá-lo a otimizar os seus
            processos produtivos.
          </p>
        </CardtRight>
        <CardtRight>
          <h2>2. Proposta de solução</h2>
          <p>
            A partir do contato inicial e do entendimento da problemática,
            apresentamos uma proposta de solução para o cliente com o cronograma
            de desenvolvimento e o orçamento detalhados.
          </p>
        </CardtRight>
        <CardtRight>
          <h2>3. Formalização</h2>
          <p>
            Formalizamos um contrato para preservar os direitos e deveres a
            serem cumpridos pelas partes envolvidas no desenvolvimento do
            projeto.
          </p>
        </CardtRight>
      </ContentRight>
    </Container>
  );
}
export default About;
