import styled from 'styled-components';
import { colors } from '../../assets/styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background: ${colors.primary};
  bottom: 0;
  border-top: 1px inset ${colors.secundary};
`;

export const Copyright = styled.div`
  p,
  a {
    color: ${colors.secundary};
    font: 400 1rem Poppins;
  }

  @media (max-width: 700px) {
    p,
    a {
      font: 400 0.7rem Poppins;
    }
  }
`;

export const Address = styled.div`
  margin: 2rem;
  p {
    color: ${colors.secundary};
    font: 400 1rem Poppins;
  }

  @media (max-width: 700px) {
    p {
      font: 400 0.8rem Poppins;
    }
  }
`;
