import styled from 'styled-components';
import { colors } from '../../assets/styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 650px;
  background: ${colors.primary};

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: 'c1 c2';
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
  border-right: 1px solid ${colors.secundary};
  grid-area: c1;

  h1,
  h2,
  p {
    color: ${colors.secundary};
    text-shadow: 2px 2px #222;
  }

  h1,
  h2 {
    font: 700 2.6rem Poppins;
  }

  p {
    font: 400 1.2rem Poppins;
  }

  @media (max-width: 700px) {
    h1,
    h2 {
      font: 700 1rem Poppins;
    }

    p {
      font: 400 0.7rem Poppins;
    }
  }
`;

export const ContentRight = styled(ContentLeft)`
  grid-area: c2;

  h1,
  h2,
  p {
    color: ${colors.primary};
    text-shadow: none;
  }

  h3 {
    color: ${colors.secundary};
    text-shadow: 2px 2px #222;
  }

  h1,
  h2 {
    font: 700 1.4rem Poppins;
  }

  @media (max-width: 700px) {
    h1,
    h2,
    h3 {
      font: 700 0.8rem Poppins;
    }

    p {
      font: 400 0.7rem Poppins;
    }
  }
`;

export const CardLeft = styled.div`
  margin: 0.3rem;
  width: 25vw;
  height: 25vh;

  @media (max-width: 700px) {
    width: 40vw;
    height: 20vh;
    margin-bottom: 2rem;
  }
`;

export const CardtRight = styled(CardLeft)`
  width: 35vw;
  height: 22vh;
  border-radius: 10px;
  padding: 0.6rem;
  margin-right: 2rem;
  background: ${colors.secundary};
  border: 5px solid ${colors.tertiary};
  -webkit-box-shadow: 3px 2px 3px 1px #222;
  -moz-box-shadow: 3px 2px 3px 1px #222;
  box-shadow: 3px 2px 3px 1px #222;

  @media (max-width: 700px) {
    padding: 0.2em;
    margin-bottom: 0.2rem;
    width: 100%;
    height: auto;
  }
`;
