import React from 'react';
import GlobalStyle from './assets/styles/GlobalStyle';

import Header from './components/Header';
import Contact from './components/Contact';
import Stick from './components/Stick';
import Footer from './components/Footer';
import Main from './pages/Main';
import About from './pages/About';
import Solutions from './pages/Solutions';
import Clients from './pages/Clients';
import Equip from './pages/Equip';

function App() {
  return (
    <>
      <GlobalStyle />
      <Header />
      <Main />
      <Stick inverted title="QUEM SOMOS ?" />
      <About />
      <Stick title="SOLUÇÕES" />
      <Solutions />
      <Stick inverted title="CLIENTES" />
      <Clients />
      <Stick title="EQUIPE" />
      <Equip />
      <Stick inverted title="CONTATO" />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
