import React from 'react';

import { Container, Address, Copyright } from './styles';

function Footer() {
  return (
    <Container>
      <Address>
        <p>Endereço: Rua XXX, n°. XX - Bairro XXX</p>
        <p>Ouro Preto - MG, Brasil</p>
        <p>CEP: XX.XXX-XXX</p>
        <p>CNPJ: XXX.XXX.XXX-XX</p>
      </Address>
      <Copyright>
        <p>
          © Copyright - 2021. Desenvolvido por{' '}
          <a href="https://patrickmoreira.netlify.app/">
            <b>Patrick Moreira</b>
          </a>
          .
        </p>
      </Copyright>
    </Container>
  );
}

export default Footer;
