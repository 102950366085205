import React, { useRef } from 'react';
import { Form } from '@unform/web';
import { Container, Content, Button } from './styles';

import Input from '../Input';

function Contact() {
  const formRef = useRef(null);
  const initialState = {};

  return (
    <Container id="contact">
      <Content>
        <Form initialData={initialState} ref={formRef} onSubmit={() => {}}>
          <Input name="name" type="name" placeholder="Seu nome" />
          <Input
            name="email"
            type="email"
            placeholder="Seu endereço de e-mail"
          />
          <Input name="msg" type="area" placeholder="Sua mensagem ..." />
          <Button type="submit">Solicitar orçamento</Button>
        </Form>
      </Content>
    </Container>
  );
}

export default Contact;
