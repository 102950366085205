import styled from 'styled-components';
import { colors } from '../../assets/styles';

export const Container = styled.div`
  width: 100%;
  height: 720px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: ${colors.primary};

  @media (max-width: 700px) {
    height: 600px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 80%;

  h1 {
    color: ${colors.secundary};
    font: 700 4rem Poppins;
    text-align: center;
    text-shadow: 2px 2px #222;
  }

  .logo_main {
    width: 600px;
    height: auto;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 1.2rem;
    }
    .logo_main {
      width: 300px;
      margin: 5rem;
    }
  }
`;

export const Button = styled.button`
  width: 15rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.primary};
  color: ${colors.secundary};
  border: 3px solid ${colors.secundary};
  border-radius: 5px;
  font: 700 1.4rem Poppins;
  cursor: pointer;

  :hover {
    background: ${colors.secundary};
    color: ${colors.primary};
    transition: all 1s;
  }
  @media (max-width: 700px) {
    width: 10rem;
    font: 700 1rem Poppins;
  }
`;
