export default {
  primary: '#3F3D56',
  secundary: '#E1E1E1',
  tertiary: '#C4C4C4',
  quaternary: '#999999',

  info: '#36679E',
  success: '#3F7636',
  failure: '#DA5C5C',
};
