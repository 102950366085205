import { createGlobalStyle } from 'styled-components';
import { colors } from '.';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    max-width: 100vw;
    max-height: 100vh;

    width: 100%;
    height: 100%;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background: ${colors.secundary};
  }

  body,
  input,
  button,
  textarea {
    font: 700 1rem Poppins;
    border: 0;
  }

  input {
    background: ${colors.tertiary};
    color: ${colors.quaternary};
  }

  button {
    cursor: pointer;
    background: none;
  }

  a {
    text-decoration: none;
  }

  button.rec-dot {
    box-shadow: 0 0 1px 3px ${colors.secundary};
    margin-bottom: 3rem;
  }

  button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus {
    background: ${colors.secundary};
    box-shadow: 0 0 1px 3px ${colors.secundary};
  }

  /* square buttons */
  .rec.rec-arrow {
    border-radius: 50%;
    background: ${colors.secundary};
    color: ${colors.primary};
  }

  .rec.rec-arrow:hover {
    background: ${colors.secundary};
    color: ${colors.primary};
  }

  /* hide disabled buttons */
  .rec.rec-arrow:disabled {
    visibility: hidden;
  }


  /* disable default outline on focused items */
  /* add custom outline on focused items */
  .rec-carousel-item:focus {
      outline: none;
      box-shadow: inset 0 0 1px 1px lightgrey;
  }

  @media(min-width: 700px){
    :root{
      font-size: 80%;
    }
  }
`;
