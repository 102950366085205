import React from 'react';
import Carousel from 'react-elastic-carousel';

import { Container, ContainerCarousel, CardProject } from './styles';
import logo from '../../assets/images/logos/logo3.svg';

function Solution() {
  const projects = [
    {
      id: 1,
      title: 'Modelo 1',
      subtitle: 'Modelo de otimização 1 ...',
    },
    {
      id: 2,
      title: 'Modelo 2',
      subtitle: 'Modelo de otimização 2 ...',
    },
    {
      id: 3,
      title: 'Modelo 3',
      subtitle: 'Modelo de otimização 3 ...',
    },
  ];

  return (
    <Container id="solutions">
      <ContainerCarousel>
        <Carousel id="#carousel">
          {projects.map((project) => (
            <CardProject key={project.id}>
              <img src={logo} width={120} alt="logo" />
              <h1>{project.title}</h1>
              <p>{project.subtitle}</p>
            </CardProject>
          ))}
        </Carousel>
      </ContainerCarousel>
    </Container>
  );
}
export default Solution;
